import React, { useContext, useState } from 'react';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';
import { Box, Text, Divider, useTheme } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  CardContainer,
  HeaderStack,
  ExpandButton,
  AdditionalDetailsBox,
  DetailsBox,
  StyledChevron,
  SecondaryTextTypography,
} from './common/components';
import { scrollToCard } from './common/utils';

export interface BaseCardProps {
  bvid?: string;
  name?: string;
  isExpandable?: boolean;
  onDetailsClick?: () => void;
  renderIcon: () => React.ReactNode;
  renderHeader: () => React.ReactNode;
  renderExpandedContent?: () => React.ReactNode;
  footerText?: string;
}

export const BaseCard: React.FC<BaseCardProps> = ({
  name = '',
  isExpandable = true,
  onDetailsClick,
  renderIcon,
  renderHeader,
  renderExpandedContent,
  footerText,
}) => {
  const theme = useTheme();
  const { trackEventGA4 } = useContext(AppConfig);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isExpanded) {
      const target = event.target as HTMLElement;
      const cardElement = target.closest('.base-card-container');
      if (cardElement) {
        scrollToCard(cardElement);
      }
    }

    trackEventGA4({
      event_name: 'search_results_interaction',
      type: 'card click',
      action: isExpanded ? 'collapse' : 'expand',
    });

    setIsExpanded(!isExpanded);
  };

  return (
    <CardContainer className="base-card-container">
      <HeaderStack onClick={onDetailsClick ? onDetailsClick : undefined}>
        {renderIcon()}
        <Box sx={{ flex: 1 }} mt="4px" ml="14px">
          {renderHeader()}
        </Box>
        {onDetailsClick && <StyledChevron onClick={onDetailsClick} />}
      </HeaderStack>

      {isExpandable && !isExpanded ? (
        <>
          <Divider
            sx={{ margin: '18px 0 8px', borderColor: theme.palette.divider }}
          />
          <ExpandButton onClick={handleExpandToggle}>
            <SecondaryTextTypography variant="body2">
              Expand Details
            </SecondaryTextTypography>
            <KeyboardArrowDown fontSize="small" sx={{ marginLeft: '2px' }} />
          </ExpandButton>
        </>
      ) : isExpandable && renderExpandedContent ? (
        <>
          <Divider
            sx={{ margin: '18px 0', borderColor: theme.palette.divider }}
          />
          {renderExpandedContent()}

          {footerText && onDetailsClick && (
            <AdditionalDetailsBox onClick={onDetailsClick}>
              <DetailsBox>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                  <SecondaryTextTypography variant="body2" mb={0.5}>
                    See additional details for:
                  </SecondaryTextTypography>
                  <Text
                    variant="body2"
                    fontWeight="bold"
                    color="primary"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {footerText}
                  </Text>
                </Box>
                <OpenInNew
                  color="primary"
                  fontSize="small"
                  sx={{ position: 'absolute', right: 0, top: 0 }}
                />
              </DetailsBox>
            </AdditionalDetailsBox>
          )}

          {isExpandable && (
            <ExpandButton onClick={handleExpandToggle} mt={3}>
              <SecondaryTextTypography variant="body2">
                Close Details
              </SecondaryTextTypography>
              <KeyboardArrowUp fontSize="small" />
            </ExpandButton>
          )}
        </>
      ) : null}
    </CardContainer>
  );
};
