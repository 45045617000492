import React from 'react';
import { SerializedObituaryDetails } from '@ltvco/refresh-lib/v1';
import { ObituaryResultsCardv2 } from '@ltvco/refresh-lib/v2';
import { useSortedObitResults } from '@ltvco/refresh-lib/hooks';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { MobileObituaryCard as MobileObituaryCardVariation1 } from './NewCardsDesignVariation1/ObituaryCards/MobileObituaryCard';
interface ObituaryResultsCardProps {
  searchObituaryResult: SerializedObituaryDetails;
  searchObituaryQuery: any;
}

export const ObituaryCards = ({
  searchObituaryResult,
  searchObituaryQuery,
}: ObituaryResultsCardProps) => {
  const { isMobile } = useScreenSize();
  const isInNewCardDesignVariation1 = useFeatureIsOn('prop-2906');
  const isInMobileAndNewCardDesignVariation1 =
    isMobile && isInNewCardDesignVariation1;

  const sortedObits = useSortedObitResults(
    searchObituaryResult,
    searchObituaryQuery
  );

  return (
    <>
      {sortedObits.map((obituary: any, index: number) => {
        if (isInMobileAndNewCardDesignVariation1) {
          return (
            <React.Fragment key={index}>
              <MobileObituaryCardVariation1 {...obituary} />
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={index}>
            <ObituaryResultsCardv2 obituaryData={obituary} />
          </React.Fragment>
        );
      })}
    </>
  );
};
