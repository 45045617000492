import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Stack,
  Divider,
  Pagination,
  StyledSwitch,
  Text,
} from '@ltvco/refresh-lib/theme';
import { formatListingsText, paginationResultsRangeText } from './utils';
import {
  Main,
  StyledDrawer,
  StyledTopBar,
  MobileMapButton,
} from './components';

import { Fragment, useState } from 'react';
import {
  DragToSearch,
  ListingDataCard,
  ListingDataTable,
  ListingDetailsModal,
  ListingPoints,
  ListingResultsDataViewToggle,
  ListingResultsFilters,
  ListingsSortFilter,
  MapboxMap,
  NoListingsResults,
  ParcelBoundaries,
  RapidsListingsSearch,
  SatelliteToggle,
  SerializedListing,
  SerializedListingsData,
  SerializedPreforeclosure,
  useAccountLists,
  useSearchListings,
  ZoomToSearch,
} from '@ltvco/refresh-lib/v1';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { List, Map } from '@mui/icons-material';

type searchTypes = 'for_sale' | 'pre_foreclosures' | 'rentals';
type marketCountsTypes = 'listings' | 'preforeclosures' | 'rentals';

export function SearchListingResults() {
  const { isMobile, isDesktop } = useScreenSize();
  const { data: lists = [] } = useAccountLists();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(isDesktop);
  const [isMapToggleOn, setIsMapToggleOn] = useState(isDesktop);
  const [isTableView, setIsTableView] = useState(false);

  const handleMapToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(!open);
    setIsMapToggleOn(open);
  };

  const handleMobileMapClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
    setOpen(!open);
    setIsMapToggleOn(open);
  };

  const PER_PAGE = isMapToggleOn ? 40 : 20;
  const PER_PAGE_STR = String(PER_PAGE);
  // 'byram' is the name of the DD Plan which includes Listhub data
  // to the listings endpoints response (WU - 2022/11/08).
  const ddPlanName = 'byram';
  const searchType = searchParams.get('search_type') || 'for_sale';
  const minPrice = searchParams.get('min_price') || '';
  const maxPrice = searchParams.get('max_price') || '';
  const bedrooms = searchParams.get('bedrooms') || '';
  const bathrooms = searchParams.get('bathrooms') || '';
  const rentalsMinPrice = searchParams.get('rentals_min_price') || '';
  const rentalsMaxPrice = searchParams.get('rentals_max_price') || '';
  const rentalsBedrooms = searchParams.get('rentals_bedrooms') || '';
  const rentalsBathrooms = searchParams.get('rentals_bathrooms') || '';
  const sortListings = searchParams.get('sort_listings') || '';
  const type = searchParams.get('type') || '';
  const shouldDisplayFilters = searchType !== 'pre_foreclosures';
  const shouldAllowSorting = searchType !== 'pre_foreclosures';

  let searchData = {
    dd_plan: ddPlanName,
    rentals_limit: searchType === 'rentals' ? PER_PAGE_STR : '0',
    pfc_limit: searchType === 'pre_foreclosures' ? PER_PAGE_STR : '0',
    listings_limit:
      searchType !== 'rentals' && searchType !== 'pre_foreclosures'
        ? PER_PAGE_STR
        : '0',
    bathrooms,
    bedrooms,
    min_price: minPrice,
    max_price: maxPrice,
    rentals_min_price: rentalsMinPrice,
    rentals_max_price: rentalsMaxPrice,
    rentals_bedrooms: rentalsBedrooms,
    rentals_bathrooms: rentalsBathrooms,
    brand: searchParams.get('brand') || '',
    city: searchParams.get('city') || '',
    lat: searchParams.get('lat') || '',
    listings_distance: searchParams.get('listings_distance') || '',
    listings_page: searchParams.get('listings_page') || '',
    long: searchParams.get('long') || '',
    map_view: searchParams.get('map_view') || '',
    max_lat: searchParams.get('max_lat') || '',
    max_long: searchParams.get('max_long') || '',
    min_lat: searchParams.get('min_lat') || '',
    min_long: searchParams.get('min_long') || '',
    page: searchParams.get('page') || '',
    pfc_distance: searchParams.get('pfc_distance') || '',
    pfc_page: searchParams.get('pfc_page') || '',
    rentals_page: searchParams.get('rentals_page') || '',
    search_type: searchType,
    sort_listings: sortListings,
    state: searchParams.get('state') || '',
    type: searchParams.get('type') || '',
    zip5: searchParams.get('zip5') || '',
  };

  let { data: searchListingsResult = [], isFetched } =
    useSearchListings(searchData);

  const marketAvailableBySearchType: Record<searchTypes, marketCountsTypes> = {
    for_sale: 'listings',
    pre_foreclosures: 'preforeclosures',
    rentals: 'rentals',
  };

  const pageParamBySearchType: Record<searchTypes, string> = {
    for_sale: 'listings_page',
    pre_foreclosures: 'pfc_page',
    rentals: 'rentals_page',
  };

  const searchValue = searchData.zip5
    ? searchData.zip5
    : `${searchData.city}, ${searchData.state}`;

  const marketName: string =
    marketAvailableBySearchType[(searchType as searchTypes) || 'for_sale'];
  const totalResults =
    (searchListingsResult as SerializedListingsData).listing_teaser?.meta
      ?.available?.market?.[marketName as marketCountsTypes] || 0;
  const numOfPagesInPagination = Math.ceil(totalResults / PER_PAGE);

  const paginationCountText = `Shown ${paginationResultsRangeText(
    searchData.page,
    PER_PAGE,
    totalResults
  )} of ${totalResults}`;

  const handlePageChange = (newPage: string) => {
    const paramNameBySearchType =
      pageParamBySearchType[(searchType as searchTypes) || 'for_sale'];

    setSearchParams((searchParams) => {
      searchParams.set('page', newPage);
      searchParams.set(paramNameBySearchType, newPage);
      return searchParams;
    });
  };

  const determineListingData = (
    data: SerializedListingsData,
    searchType: string
  ) => {
    if (searchType === 'rentals') {
      return data?.listing_teaser?.rentals;
    }

    if (searchType === 'pre_foreclosures') {
      return data?.listing_teaser?.preforeclosures;
    }

    return data?.listing_teaser?.listings;
  };

  const listingData = determineListingData(
    searchListingsResult as SerializedListingsData,
    searchType
  );

  const renderDataCards = () => {
    return listingData?.map(
      (data: SerializedListing | SerializedPreforeclosure, index: number) => {
        const typedData = data as SerializedListing;
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={open ? 6 : 4}
            lg={open ? 6 : 3}
            key={index}
          >
            {data.isPreforeclosure ? (
              <ListingDataCard
                lists={lists}
                listingType={searchType}
                resultData={data}
              />
            ) : (
              <ListingDetailsModal
                listingKey={typedData?.property?.listing_detail?.key || ''}
              >
                <ListingDataCard
                  lists={lists}
                  listingType={searchType || 'for_sale'}
                  resultData={data}
                />
              </ListingDetailsModal>
            )}
          </Grid>
        );
      }
    );
  };

  const mobileButtonIconAndText = () => {
    const iconName = open ? 'List' : 'Map';
    const Icon = open ? List : Map;

    return (
      <>
        <Icon sx={(theme) => ({ marginRight: theme.spacing(0.5) })} />
        {iconName}
      </>
    );
  };

  return (
    <>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      >
        {isDesktop && (
          <>
            <StyledTopBar
              sx={{ top: '55px' }}
              justifyContent="space-between"
              direction="row"
            >
              <Stack direction="row">
                <RapidsListingsSearch searchType={searchType} />
                {shouldDisplayFilters && (
                  <ListingResultsFilters
                    searchType={searchType}
                    bedrooms={+bedrooms}
                    bathrooms={+bathrooms}
                    maxPrice={maxPrice}
                    minPrice={minPrice}
                    rentalsBedrooms={+rentalsBedrooms}
                    rentalsBathrooms={+rentalsBathrooms}
                    rentalsMinPrice={rentalsMinPrice}
                    rentalsMaxPrice={rentalsMaxPrice}
                    type={type}
                    setSearchParams={setSearchParams}
                  />
                )}
              </Stack>
              <Stack alignItems="center" direction="row">
                <Text>Map</Text>
                <StyledSwitch checked={open} onChange={handleMapToggle} />
              </Stack>
            </StyledTopBar>
            <Stack
              sx={{
                position: open ? 'relative' : '',
                height: open ? 'calc(100vh - 125px)' : '',
              }}
            >
              <Main open={open}>
                {isFetched && (
                  <Stack
                    sx={(theme) => ({
                      height: '100%',
                      overflow: open ? 'scroll' : '',
                      gap: theme.spacing(1.5),
                    })}
                  >
                    {totalResults > 0 ? (
                      <>
                        <Stack direction="row" justifyContent="space-between">
                          <Stack>
                            <Stack direction="row">
                              <Text fontWeight={400} variant="h4">
                                Results for
                              </Text>
                              <Text
                                variant="h4"
                                sx={{ display: 'inline-block' }}
                              >
                                &nbsp;
                                {formatListingsText(searchData, searchType)}
                              </Text>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={2}>
                              <Text fontSize="14px">{paginationCountText}</Text>
                              {shouldAllowSorting && (
                                <Fragment>
                                  <Divider
                                    orientation="vertical"
                                    sx={{ height: '24px' }}
                                  />
                                  <ListingsSortFilter
                                    currentSortType={sortListings}
                                    setSearchParams={setSearchParams}
                                  />
                                </Fragment>
                              )}
                            </Stack>
                          </Stack>
                          <ListingResultsDataViewToggle
                            isTableView={isTableView}
                            onViewChange={setIsTableView}
                          />
                        </Stack>
                        {isTableView ? (
                          <ListingDataTable
                            listingType={searchType as searchTypes}
                            listingData={listingData}
                          />
                        ) : (
                          <Grid
                            container
                            rowSpacing={0.5}
                            columnSpacing={2}
                            px={open ? '4px' : '0'}
                          >
                            {renderDataCards()}
                          </Grid>
                        )}
                        <Stack
                          spacing={2}
                          direction={'row'}
                          sx={{ justifyContent: 'center' }}
                        >
                          {numOfPagesInPagination > 1 && (
                            <Pagination
                              page={
                                searchData.page ? Number(searchData.page) : 1
                              }
                              count={numOfPagesInPagination}
                              onChange={(_: any, page: number) =>
                                handlePageChange(String(page))
                              }
                              variant="outlined"
                              shape="rounded"
                              sx={(theme) => ({
                                '.MuiPaginationItem-root.Mui-selected': {
                                  color: `${theme.palette.primary.contrastText}`,
                                  backgroundColor: `${theme.palette.primary.main}`,
                                },
                              })}
                            />
                          )}
                        </Stack>
                      </>
                    ) : (
                      <NoListingsResults
                        prevSearchValue={searchValue}
                        searchType={searchType}
                      />
                    )}
                  </Stack>
                )}
              </Main>
              {open && (
                <StyledDrawer variant="persistent" anchor="right" open={open}>
                  <MapboxMap
                    height="calc(100vh - 125px)"
                    zoomLevel={12}
                    navigation={true}
                  >
                    {(map) => (
                      <>
                        <ListingPoints
                          map={map}
                          results={listingData || []}
                          moveCenter={true}
                          searchType={searchType}
                        />
                        <DragToSearch
                          map={map}
                          setSearchParams={setSearchParams}
                        />
                        <ZoomToSearch
                          map={map}
                          setSearchParams={setSearchParams}
                        />
                        <ParcelBoundaries map={map} toggle={true} />
                        <SatelliteToggle map={map} autoSwitch={true} />
                      </>
                    )}
                  </MapboxMap>
                </StyledDrawer>
              )}
            </Stack>
          </>
        )}
        {!isDesktop && (
          <Stack sx={(theme) => ({ padding: open ? '0' : theme.spacing(2) })}>
            {open ? (
              <MapboxMap
                height="calc(100vh - 123px)"
                zoomLevel={12}
                navigation={true}
              >
                {(map) => (
                  <>
                    <ListingPoints
                      map={map}
                      results={listingData || []}
                      moveCenter={true}
                      searchType={searchType}
                    />
                    <DragToSearch map={map} setSearchParams={setSearchParams} />
                    <ZoomToSearch map={map} setSearchParams={setSearchParams} />
                    <ParcelBoundaries map={map} toggle={true} />
                    <SatelliteToggle map={map} autoSwitch={true} />
                  </>
                )}
              </MapboxMap>
            ) : (
              isFetched && (
                <Stack sx={open ? { overflow: 'scroll' } : {}}>
                  {totalResults > 0 ? (
                    <>
                      <Stack
                        sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
                      >
                        <Stack>
                          <Stack direction="row">
                            <Text variant="h4" fontSize={20} fontWeight={400}>
                              Results for
                            </Text>
                            <Text variant="h4" sx={{ display: 'inline-block' }}>
                              &nbsp;{formatListingsText(searchData, searchType)}
                            </Text>
                          </Stack>
                          <Text fontSize="14px">{paginationCountText}</Text>
                        </Stack>
                      </Stack>
                      <Grid container spacing={2}>
                        {renderDataCards()}
                      </Grid>
                      {numOfPagesInPagination > 1 && (
                        <Stack
                          spacing={2}
                          direction={'row'}
                          sx={{ justifyContent: 'center' }}
                        >
                          <Pagination
                            page={searchData.page ? Number(searchData.page) : 1}
                            count={numOfPagesInPagination}
                            onChange={(_: any, page: number) =>
                              handlePageChange(String(page))
                            }
                            variant="outlined"
                            shape="rounded"
                            className="pagination"
                            sx={(theme) => ({
                              '.MuiPaginationItem-root.Mui-selected': {
                                color: `${theme.palette.primary.contrastText}`,
                                backgroundColor: `${theme.palette.primary.main}`,
                              },
                            })}
                          />
                        </Stack>
                      )}
                    </>
                  ) : (
                    <NoListingsResults
                      prevSearchValue={searchValue}
                      searchType={searchType}
                    />
                  )}
                </Stack>
              )
            )}
          </Stack>
        )}
      </Stack>
      {!isDesktop && (
        <MobileMapButton onClick={handleMobileMapClick} variant="outlined">
          {mobileButtonIconAndText()}
        </MobileMapButton>
      )}
    </>
  );
}
