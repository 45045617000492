import { titleize } from '@ltvco/refresh-lib/utils';
import { commonTypes } from '@ltvco/refresh-lib/v1';
import { NameData } from './types';

const SCROLL_OFFSET = 215;
const MAX_NAME_LENGTH = 35;
export const MAX_LIST_ITEMS = 5;

// Helper function to deduplicate arrays
export const deduplicateArray = <T,>(array: T[]): T[] => {
  return Array.from(new Set(array));
};

// Helper function to deduplicate arrays of objects by a key
export const deduplicateObjectsByKey = <T extends Record<string, any>>(
  array: T[],
  key: keyof T
): T[] => {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[key];
    if (seen.has(value)) return false;
    seen.add(value);
    return true;
  });
};

export const formatName = (name: string) => {
  return titleize(name || '').toLowerCase() || '';
};

export const excludeAgeFromName = (name: string, age?: string) => {
  if (!age) return name;

  if (name.includes(`, ${age}`)) {
    return name.replace(`, ${age}`, '').slice(0, MAX_NAME_LENGTH);
  }

  return name;
};

export const scrollToCard = (element: Element) => {
  // Scroll to the beginning of the card with offset to avoid being hidden
  const cardRect = element.getBoundingClientRect();
  const offsetPosition = window.pageYOffset + cardRect.top - SCROLL_OFFSET;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

export const formatShorthandDate = (
  date: commonTypes.Date | undefined,
  includeDay?: boolean
) => {
  if (!date) {
    return;
  }

  if (date.parsed) {
    const { parsed } = date;
    if (parsed.year && parsed.month) {
      return new Date(
        parsed.year,
        parsed.month - 1,
        parsed.day || 1
      ).toLocaleDateString('en-us', {
        year: 'numeric',
        month: includeDay ? '2-digit' : 'long',
        day: includeDay ? 'numeric' : undefined,
      });
    }
  }
};

export const getUniqueRelatives = (
  relatives: string[] = [],
  relativesBvIds: NameData | NameData[] = []
) => {
  // Deduplicate relatives while preserving the relation with relativesBvIds
  const uniqueRelatives: string[] = [];
  const uniqueRelativesBvIds: string[] = [];

  // Use Map to maintain order and preserve the bvid connections
  const relativeMap = new Map<string, string>();
  (relatives || []).forEach((relative, index) => {
    const relativeBvId = Array.isArray(relativesBvIds)
      ? relativesBvIds[index]?.bvid
      : relativesBvIds.bvid;
    relativeMap.set(relative.toLowerCase(), relativeBvId || '');
  });

  // Convert back to arrays
  relativeMap.forEach((bvid, name) => {
    uniqueRelatives.push(name);
    uniqueRelativesBvIds.push(bvid);
  });

  return { uniqueRelatives, uniqueRelativesBvIds };
};
