import { Person, Spellcheck } from '@mui/icons-material';
import { DarkTooltip, useTheme, Stack } from '@ltvco/refresh-lib/theme';
import { openReportInNewTab } from '@ltvco/refresh-lib/v1';
import { constants } from 'appConstants';
import { BaseCard } from '../BaseCard';
import {
  PersonIconWrapper,
  InfoSection,
  NameTypography,
  SecondaryTextTypography,
  SecondaryTextSubtitleTypography,
  CustomList,
  RelativesList,
} from '../common/components';
import {
  formatName,
  excludeAgeFromName,
  getUniqueRelatives,
} from '../common/utils';
import { PersonCardData } from '../common/types';

export function MobilePersonCard({
  bvid,
  name,
  nameMatched,
  birthInfo,
  locations,
  aliases,
  relatives,
  onSuccess,
  age,
  relativesNotSerialized,
}: PersonCardData) {
  const theme = useTheme();
  const { uniqueRelatives, uniqueRelativesBvIds } = getUniqueRelatives(
    relatives,
    relativesNotSerialized
  );

  const openReport = (bvid: string) => {
    openReportInNewTab({ bvid, searchType: 'person' }, constants.links.baseUrl);
  };

  const handleRelativeClick = (index: number) => {
    const relativeBvId = uniqueRelativesBvIds[index];
    if (relativeBvId) {
      openReport(relativeBvId);
    }
  };

  const handleOpenMainPersonReport = () => {
    onSuccess?.();
    openReport(bvid);
  };

  const mainLocation = locations?.[0];
  const formattedName = formatName(name || '');
  const safeName = name || '';

  return (
    <BaseCard
      name={safeName}
      isExpandable={true}
      onDetailsClick={handleOpenMainPersonReport}
      renderIcon={() => (
        <PersonIconWrapper>
          <Person color="primary" fontSize="small" />
        </PersonIconWrapper>
      )}
      renderHeader={() => (
        <>
          <NameTypography variant="subtitle1" fontWeight="bold">
            {formattedName}
            <Stack flexDirection="row">
              {nameMatched && (
                <DarkTooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={2000}
                  title="Name Matched"
                >
                  <Spellcheck
                    className="SpellCheck"
                    sx={{
                      height: '16px',
                      width: '16px',
                      margin: 'auto',
                      color: theme.palette.neighborhoodWatch?.main,
                      ml: 1,
                      mr: 1,
                    }}
                  />
                </DarkTooltip>
              )}
            </Stack>
          </NameTypography>
          <SecondaryTextTypography variant="body2">
            Born: {birthInfo || '-'}
          </SecondaryTextTypography>
          {mainLocation && (
            <SecondaryTextTypography variant="body2">
              {mainLocation}
            </SecondaryTextTypography>
          )}
        </>
      )}
      renderExpandedContent={() => (
        <InfoSection>
          <SecondaryTextSubtitleTypography variant="subtitle2">
            Aliases
          </SecondaryTextSubtitleTypography>
          {aliases && aliases.length > 0 ? (
            <CustomList items={aliases} />
          ) : (
            <SecondaryTextTypography variant="body2">-</SecondaryTextTypography>
          )}

          <SecondaryTextSubtitleTypography variant="subtitle2">
            Locations
          </SecondaryTextSubtitleTypography>
          {locations && locations.length > 0 ? (
            <CustomList items={locations as string[]} />
          ) : (
            <SecondaryTextTypography variant="body2">-</SecondaryTextTypography>
          )}

          <SecondaryTextSubtitleTypography variant="subtitle2">
            Relatives
          </SecondaryTextSubtitleTypography>
          {relatives && relatives.length > 0 ? (
            <RelativesList
              uniqueRelatives={uniqueRelatives}
              uniqueRelativesBvIds={uniqueRelativesBvIds}
              onClickHandler={handleRelativeClick}
            />
          ) : (
            <SecondaryTextTypography variant="body2">-</SecondaryTextTypography>
          )}
        </InfoSection>
      )}
      footerText={excludeAgeFromName(formattedName, age)}
    />
  );
}
