import { Grid, Avatar, Text, Button } from '@ltvco/refresh-lib/theme';
import notFound from '../images/img_404.svg';
import { useNavigate } from 'react-router-dom';
import { SidenavContainer } from 'components/sidenav/SidenavContainer';
import { goToLogin } from 'utils/goToLogin';
import { useSession } from '@ltvco/refresh-lib/v1';

export function NotFound() {
  const navigate = useNavigate();
  const {
    session: { isAuthenticated },
  } = useSession(); // should be replaced by the user_info object

  function goToDashboard() {
    navigate('/dashboard');
  }

  if (!isAuthenticated) {
    goToLogin('https://www.beenverified.com');
  }

  return (
    <SidenavContainer>
      <Grid container spacing={3} textAlign={'center'} marginTop={8}>
        <Grid item xs={12}>
          <Avatar
            src={notFound}
            alt="404"
            sx={{ width: 88, height: 88, margin: 'auto' }}
            variant="square"
          />
          <Text variant="h2" color={'primary'} fontSize={52} marginTop={2.5}>
            404
          </Text>
          <Text variant="h3" fontSize={24} marginTop={2.5}>
            This page isn't available
          </Text>
          <Text width={{ xs: '100%', md: '30%' }} margin={'auto'}>
            We searched high and low, but we couldn't find the page you're
            looking for
          </Text>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 2.5 }}
            onClick={goToDashboard}
          >
            Go to Dashboard
          </Button>
        </Grid>
      </Grid>
    </SidenavContainer>
  );
}
