import React from 'react';
import { Stack } from '@ltvco/refresh-lib/theme';
import { useContext } from 'react';
import { constants } from 'appConstants';
import {
  type ContactCompany,
  type ContactDetail,
  NoContactResults,
  type SerializedContactTeaserData,
  openReportInNewTab,
  useReportRedirect,
} from '@ltvco/refresh-lib/v1';
import { ProfessionalCard } from '@ltvco/refresh-lib/v2';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { MobileProfessionalCard as MobileProfessionalCardVariation1 } from './NewCardsDesignVariation1/ProfessionalCards/MobileProfessionalCard';
interface ContactCardProps {
  searchContactResult: SerializedContactTeaserData;
  fullName?: string;
  company?: string;
}

export const ContactCards = ({
  searchContactResult,
  fullName,
  company,
}: ContactCardProps) => {
  const { contacts = [] } = searchContactResult;
  const { routingUtils } = useContext(AppConfig);
  const { redirectToSearchContactUrl, redirectToSearchContactUrlNewTab } =
    useReportRedirect();
  const { isMobile } = useScreenSize();
  const isInNewCardDesignVariation1 = useFeatureIsOn('prop-2906');
  const isInMobileAndNewCardDesignVariation1 =
    isMobile && isInNewCardDesignVariation1;

  const getCompanyUrl = (company: string) =>
    routingUtils.searchContactUrl({ company: company });

  const handleCompanyClick = (
    company: string | ContactCompany,
    openInNewTab?: boolean
  ) => {
    let companyName = typeof company === 'string' ? company : company.name;

    if (openInNewTab) {
      redirectToSearchContactUrlNewTab({ company: companyName });
    } else {
      redirectToSearchContactUrl({ company: companyName });
    }
  };

  const handleViewReport = (contactId: string) => {
    openReportInNewTab(
      {
        contact_id: contactId,
        searchType: 'contact',
      },
      constants.links.baseUrl
    );
  };

  if (!contacts) {
    return <NoContactResults />;
  }

  return (
    <Stack spacing={2}>
      {contacts.map((result: ContactDetail) => {
        if (isInMobileAndNewCardDesignVariation1) {
          return (
            <React.Fragment key={result.id}>
              <MobileProfessionalCardVariation1
                {...result}
                onCompanyClick={(company) => handleCompanyClick(company, true)}
                onViewReport={handleViewReport}
              />
            </React.Fragment>
          );
        }

        return (
          <ProfessionalCard
            key={result.id}
            {...result}
            nameMatch={
              result.name.toLowerCase() === (fullName || '').toLowerCase()
            }
            companyToMatch={company || ''}
            onCompanyClick={(company) => handleCompanyClick(company, false)}
            getCompanyUrl={getCompanyUrl}
            onViewReport={handleViewReport}
            subsection=""
          />
        );
      })}
    </Stack>
  );
};
