import { Divider, Text, Stack } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';

interface DescriptionDatesProps {
  isOnDashboard: boolean;
  updatedDate: string;
  createdDate: string;
  lastViewedDate: string;
}

export const DescriptionDates = ({
  isOnDashboard,
  updatedDate,
  createdDate,
  lastViewedDate,
}: DescriptionDatesProps) => {
  const { isMobile } = useScreenSize();
  const textVariant = isMobile ? 'body2' : undefined;

  if (isOnDashboard) {
    return (
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Text variant={textVariant}>Updated {updatedDate}</Text>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Text variant={textVariant}>Created {createdDate}</Text>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack direction="row" spacing={1}>
      <Text variant={textVariant}>Updated {updatedDate}</Text>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderColor: 'text.secondary' }}
      />
      <Text variant={textVariant}>Created {createdDate}</Text>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderColor: 'text.secondary' }}
      />
      <Text variant={textVariant}>Viewed {lastViewedDate}</Text>
    </Stack>
  );
};
