import { Box, Stack, Text } from '@ltvco/refresh-lib/theme';
import { useEffect, useRef } from 'react';
import { NavButton, OptionResults } from 'routes/SearchPerson/components';
import { SearchHeaderContainerCarousel } from './components';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { CollectionType, OptionType } from '@ltvco/refresh-lib/v2';

interface SearchHeaderCarouselProps {
  options: OptionType[];
  selectedOption: CollectionType;
  setSelectedOption: (value: OptionType) => void;
}

const SearchHeaderCarousel = ({
  options,
  selectedOption,
  setSelectedOption,
}: SearchHeaderCarouselProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<Record<string, HTMLButtonElement | null>>({});
  const { isMobile } = useScreenSize();

  useEffect(() => {
    const container = scrollContainerRef.current;
    const selectedButton = optionRefs.current[selectedOption];

    if (container && selectedButton) {
      const containerRect = container.getBoundingClientRect();
      const buttonRect = selectedButton.getBoundingClientRect();
      const offset =
        buttonRect.left - containerRect.width / 2 + buttonRect.width / 2;

      container.scrollBy({
        left: offset,
        behavior: 'smooth',
      });
    }
  }, [selectedOption]);

  const handleSelectTab = (option: OptionType) => {
    if (option.value !== selectedOption) {
      setSelectedOption(option);
    }
  };

  return !isMobile ? (
    <SearchHeaderContainerCarousel className="navbar">
      {options
        .filter((option) => option.visible)
        .map((option) => (
          <NavButton
            key={option.value}
            onClick={() => setSelectedOption(option)}
            className={selectedOption === option.value ? 'active' : ''}
            sx={{
              marginRight: '5px',
            }}
          >
            <Stack flexDirection="row" mt={0} alignItems="center">
              <OptionResults
                className="options-results"
                sx={{
                  width: parseInt(option.length) > 9 ? '40px' : '25px',
                  marginRight: '5px',
                }}
              >
                {option.length ? option.length : '0'}
              </OptionResults>
              <Text variant="body1" m={0} noWrap>
                {option.label}
              </Text>
            </Stack>
          </NavButton>
        ))}
    </SearchHeaderContainerCarousel>
  ) : (
    <SearchHeaderContainerCarousel className="navbar">
      <Box
        ref={scrollContainerRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          '::-webkit-scrollbar': { display: 'none' },
          flexGrow: 1,
          whiteSpace: 'nowrap',
          gap: '4px',
        }}
      >
        {options
          .filter((option) => option.visible)
          .map((option) => (
            <NavButton
              key={option.value}
              ref={(el) => (optionRefs.current[option.value] = el)}
              onClick={() => handleSelectTab(option)}
              className={selectedOption === option.value ? 'active' : ''}
              sx={{ marginRight: '2px' }}
            >
              <Stack flexDirection="row" mt={0} alignItems="center">
                <OptionResults
                  className="options-results"
                  sx={{
                    width: parseInt(option.length) > 9 ? '40px' : '25px',
                    marginRight: '4px',
                  }}
                >
                  {option.length ? option.length : '0'}
                </OptionResults>
                <Text variant="body1" m={0} noWrap>
                  {option.label}
                </Text>
              </Stack>
            </NavButton>
          ))}
      </Box>
    </SearchHeaderContainerCarousel>
  );
};

export default SearchHeaderCarousel;
