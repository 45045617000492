import React from 'react';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import {
  PersonSearchNoResults,
  SerializedPropertyReport,
} from '@ltvco/refresh-lib/v1';
import { ResidentCardv2 } from '@ltvco/refresh-lib/v2';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { MobileResidentCard as MobileResidentCardVariation1 } from './NewCardsDesignVariation1/ResidentCards/MobileResidentCard';
import { Stack } from '@ltvco/refresh-lib/theme';

interface ResidentCardProps {
  searchResidentResult: SerializedPropertyReport;
}

export const ResidentCards = ({ searchResidentResult }: ResidentCardProps) => {
  const { isMobile } = useScreenSize();
  const isInNewCardDesignVariation1 = useFeatureIsOn('prop-2906');
  const isInMobileAndNewCardDesignVariation1 =
    isMobile && isInNewCardDesignVariation1;

  if (!searchResidentResult) {
    return <PersonSearchNoResults />;
  }

  const residents = searchResidentResult?.properties?.[0]?.residents || [];

  if (isInMobileAndNewCardDesignVariation1) {
    return (
      <Stack spacing={2}>
        {residents.map((resident) => {
          return (
            <React.Fragment key={resident.id}>
              <MobileResidentCardVariation1 {...resident} />
            </React.Fragment>
          );
        })}
      </Stack>
    );
  }

  return <ResidentCardv2 residents={residents} />;
};
