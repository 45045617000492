import { Person } from '@mui/icons-material';
import { reportGetters } from '@ltvco/refresh-lib/v1';
import { BaseCard } from '../BaseCard';
import {
  PersonIconWrapper,
  NameTypography,
  SecondaryTextTypography,
} from '../common/components';
import { formatName, formatShorthandDate } from '../common/utils';
import { ObituaryCardData } from '../common/types';
import { constants } from 'appConstants';
import { useQueryParams } from 'utils/useQueryParams';

export function MobileObituaryCard({
  id,
  name,
  dob,
  dod,
  city,
  state,
}: ObituaryCardData) {
  const queryParams = useQueryParams();
  const handleOpenObituaryReport = async () => {
    queryParams.set('id', id || '');
    const response = await reportGetters.obituary({ queryParams });
    const permalink = response?.report?.permalink;
    const windowObjectReference = window.open();

    if (!permalink) {
      if (windowObjectReference) {
        windowObjectReference.close();
      }

      return;
    }

    // It's possible for the browser to block opening a new window, in which case windowObjectReference will be null.
    // In that case, we just redirect the current window.
    const windowTarget = windowObjectReference || window;
    windowTarget.location.href = `${constants.links.baseUrl}/report/obituary?permalink=${permalink}`;
  };

  const formattedName = formatName(name || '');

  return (
    <BaseCard
      name={name}
      isExpandable={false}
      onDetailsClick={handleOpenObituaryReport}
      renderIcon={() => (
        <PersonIconWrapper>
          <Person color="primary" fontSize="small" />
        </PersonIconWrapper>
      )}
      renderHeader={() => (
        <>
          <NameTypography variant="subtitle1" fontWeight="bold">
            {formattedName}
          </NameTypography>
          <SecondaryTextTypography variant="body2">
            Born: {formatShorthandDate(dob) || '-'}
          </SecondaryTextTypography>
          <SecondaryTextTypography variant="body2">
            Death: {formatShorthandDate(dod, true) || '-'}
          </SecondaryTextTypography>
          {city && state && (
            <SecondaryTextTypography variant="body2">
              {city}, {state}
            </SecondaryTextTypography>
          )}
        </>
      )}
      footerText={formattedName}
    />
  );
}
