import { Person } from '@mui/icons-material';
import { BaseCard } from '../BaseCard';
import {
  PersonIconWrapper,
  InfoSection,
  NameTypography,
  SecondaryTextTypography,
  SecondaryTextSubtitleTypography,
  CustomList,
  RelativesList,
} from '../common/components';
import {
  formatName,
  excludeAgeFromName,
  getUniqueRelatives,
} from '../common/utils';
import { GenealogyCardData } from '../common/types';
import { constants } from 'appConstants';

export function MobileGenealogyCard({
  name,
  birthInfo,
  locations,
  relatives,
  onSuccess,
  age,
  familySearchId,
}: GenealogyCardData) {
  const { uniqueRelatives } = getUniqueRelatives(relatives, []);

  const handleOpenMainGenealogyReport = () => {
    onSuccess?.();

    if (familySearchId) {
      const windowObjectReference = window.open();
      // It's possible for the browser to block opening a new window, in which case windowObjectReference will be null.
      // In that case, we just redirect the current window.
      const windowTarget = windowObjectReference || window;

      windowTarget.location.href = `${constants.links.baseUrl}/report/ancestor?family_search_id=${familySearchId}`;
    }
  };

  const mainLocation = locations?.[0];
  const formattedName = formatName(name || '');

  return (
    <BaseCard
      name={name}
      isExpandable={true}
      onDetailsClick={handleOpenMainGenealogyReport}
      renderIcon={() => (
        <PersonIconWrapper>
          <Person color="primary" fontSize="small" />
        </PersonIconWrapper>
      )}
      renderHeader={() => (
        <>
          <NameTypography variant="subtitle1" fontWeight="bold">
            {formattedName}
          </NameTypography>
          <SecondaryTextTypography variant="body2">
            Born: {birthInfo?.trim() || '-'}
          </SecondaryTextTypography>
          {mainLocation && (
            <SecondaryTextTypography variant="body2">
              {mainLocation}
            </SecondaryTextTypography>
          )}
        </>
      )}
      renderExpandedContent={() => (
        <InfoSection>
          <SecondaryTextSubtitleTypography variant="subtitle2">
            Locations
          </SecondaryTextSubtitleTypography>
          {locations && locations.length > 0 ? (
            <CustomList items={locations as string[]} />
          ) : (
            <SecondaryTextTypography variant="body2">-</SecondaryTextTypography>
          )}

          <SecondaryTextSubtitleTypography variant="subtitle2">
            Relatives
          </SecondaryTextSubtitleTypography>
          {relatives && relatives.length > 0 ? (
            <RelativesList uniqueRelatives={uniqueRelatives} />
          ) : (
            <SecondaryTextTypography variant="body2">-</SecondaryTextTypography>
          )}
        </InfoSection>
      )}
      footerText={excludeAgeFromName(formattedName, age)}
    />
  );
}
