import { Person } from '@mui/icons-material';
import { Text } from '@ltvco/refresh-lib/theme';
import { BaseCard } from '../BaseCard';
import {
  PersonIconWrapper,
  InfoSection,
  NameTypography,
  SecondaryTextTypography,
  SecondaryTextSubtitleTypography,
} from '../common/components';
import { formatName } from '../common/utils';
import { CompaniesList } from '../common/components';
import { ProfessionalCardData } from '../common/types';

export function MobileProfessionalCard({
  id,
  name,
  title,
  companies,
  address,
  onCompanyClick,
  onViewReport,
}: ProfessionalCardData) {
  const handleOpenProfessionalReport = () => {
    onViewReport(id);
  };

  const handleCompanyClick = (index: number) => {
    const companyName =
      typeof displayedCompanies[index] === 'string'
        ? displayedCompanies[index]
        : displayedCompanies[index].name;
    onCompanyClick?.(companyName);
  };

  const formattedName = formatName(name || '');

  let displayedCompanies = companies;

  if (Array.isArray(displayedCompanies)) {
    displayedCompanies = displayedCompanies.filter((company) => company.name);
  }

  // Extract first company name
  const firstCompany =
    displayedCompanies && displayedCompanies.length > 0
      ? typeof displayedCompanies[0] === 'string'
        ? displayedCompanies[0]
        : displayedCompanies[0].name
      : null;

  return (
    <BaseCard
      name={name}
      isExpandable={true}
      onDetailsClick={handleOpenProfessionalReport}
      renderIcon={() => (
        <PersonIconWrapper>
          <Person color="primary" fontSize="small" />
        </PersonIconWrapper>
      )}
      renderHeader={() => (
        <>
          <NameTypography variant="subtitle1" fontWeight="bold">
            {formattedName}
          </NameTypography>
          {title && (
            <SecondaryTextTypography variant="body2">
              {title}
            </SecondaryTextTypography>
          )}
          {firstCompany && (
            <SecondaryTextTypography variant="body2">
              {firstCompany}
            </SecondaryTextTypography>
          )}
          {address && (
            <SecondaryTextTypography variant="body2">
              {address}
            </SecondaryTextTypography>
          )}
        </>
      )}
      renderExpandedContent={() => (
        <InfoSection>
          <SecondaryTextSubtitleTypography variant="subtitle2">
            Companies
          </SecondaryTextSubtitleTypography>
          {companies && companies.length > 0 ? (
            <CompaniesList
              companies={displayedCompanies}
              onClickHandler={handleCompanyClick}
            />
          ) : (
            <Text variant="body2">-</Text>
          )}
        </InfoSection>
      )}
      footerText={formattedName}
    />
  );
}
