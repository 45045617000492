import {
  useRecentReports,
  stringifyParams,
  generateQueryConfig,
  ReportList,
  type ReportType,
  type ReportFilter,
  DeleteAllButton,
  useReportDeleteAll,
  DeleteModal,
  type RecentReportSerialized,
} from '@ltvco/refresh-lib/v2';
import { Stack, Text, Divider, Pagination } from '@ltvco/refresh-lib/theme';
import { useQueryParams } from 'use-query-params';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import {
  LoadingReports,
  NullState,
  SearchAndFilterControls,
  RecentReportsCardDescription,
} from './components';
import { hasRunReports } from './utils';
import {
  useSession,
  useReportMonitors,
  type ReportMonitor,
  isPersonReportSearchData,
  isPhoneReportSearchData,
  isPropertyReportSearchData,
  isSocialNetworkReportSearchData,
  isVehicleReportSearchData,
  isUsernameReportSearchData,
  isContactReportSearchData,
} from '@ltvco/refresh-lib/v1';

import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { pluralizeString } from '@ltvco/refresh-lib/utils';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { HistoryRounded } from '@mui/icons-material';

const queryConfig = generateQueryConfig();

const matchesReportType = (
  monitor: ReportMonitor,
  reportTypes: ReportType[]
) => {
  const { search_data } = monitor;
  const reportTypeConditions = {
    detailed_person_report:
      search_data && isPersonReportSearchData(search_data),
    reverse_phone_report: search_data && isPhoneReportSearchData(search_data),
    property_report: search_data && isPropertyReportSearchData(search_data),
    social_network_report:
      search_data && isSocialNetworkReportSearchData(search_data),
    vehicle_report: search_data && isVehicleReportSearchData(search_data),
    username_report: search_data && isUsernameReportSearchData(search_data),
    contact_report: search_data && isContactReportSearchData(search_data),
  };
  return reportTypes.some(
    (type) => reportTypeConditions[type as keyof typeof reportTypeConditions]
  );
};
const filterMonitors = (
  currentMonitors: ReportMonitor[],
  reportTypes: ReportType[]
) => {
  return currentMonitors.filter((monitor) => {
    if (!monitor.report_type) {
      return matchesReportType(monitor, reportTypes);
    }
    return reportTypes.includes(monitor.report_type as ReportType);
  });
};
const getPermalinks = (monitors: ReportMonitor[]) => {
  if (!monitors) {
    return [];
  }
  return monitors.map((monitor) => monitor.permalink);
};

export const RecentReports: React.FC = () => {
  const {
    config: { reportTypeFilterOptions },
  } = useContext(AppConstants);
  const { session } = useSession();
  const [queryParams, setQueryParams] = useQueryParams(queryConfig);
  const { sort_by, sort_direction } = queryParams;
  const filteredParams = stringifyParams(queryParams);
  const { data: queryData, isLoading } = useRecentReports(filteredParams);
  const isMonitoredReportVariation = useFeatureIsOn('RFRSH-2558');
  const { planName } = useLimitedPlanInfo();
  const { isInternationalVinTest } = checkPlanVariations(planName);
  const [filterTypes, setFilterTypes] = useState<ReportFilter[]>(
    queryParams.report_type || []
  );

  // A/B TEST OAR-1152
  const isMonitoredReportFilterType =
    isMonitoredReportVariation && filterTypes.includes('monitored_report');
  const [currentMonitors, setCurrentMonitors] = useState<ReportMonitor[]>([]);

  const { currentMonitors: newMonitors } = useReportMonitors();
  useEffect(() => {
    if (isMonitoredReportVariation) {
      setCurrentMonitors(newMonitors);
    }
  }, [isMonitoredReportVariation, newMonitors]);

  const reportTypes: ReportType[] = filterTypes.filter(
    (type) => type !== 'monitored_report'
  );

  let filteredMonitors = currentMonitors;
  if (isMonitoredReportFilterType && reportTypes.length > 0) {
    filteredMonitors = filterMonitors(currentMonitors, reportTypes);
  }

  const totalReportsRef = useRef<number | null>(null);
  const totalReports = useMemo(() => {
    if (isLoading && totalReportsRef.current !== null) {
      return totalReportsRef.current;
    }
    if (isMonitoredReportFilterType) {
      return filteredMonitors.length || 0;
    }
    return queryData?.meta?.report_quantities?.total_reports_count || 0;
  }, [isMonitoredReportFilterType, filteredMonitors, queryData, isLoading]);
  useEffect(() => {
    if (!isLoading) {
      totalReportsRef.current = totalReports;
    }
  }, [totalReports, isLoading]);
  const totalFilteredReports =
    queryData?.meta?.report_quantities?.filtered_reports_count || 0;
  const pageCount = Math.ceil(totalFilteredReports / 20);

  const reports =
    isMonitoredReportFilterType && totalReports === 0
      ? []
      : (queryData?.reports as RecentReportSerialized[]) || [];

  const [deleteAllReportsModalOpen, setDeleteAllReportsModalOpen] =
    useState(false);

  const [currentReports, setCurrentReports] = useState(reports);

  const hasZeroReports = totalReports === 0;
  useEffect(() => {
    const monitoredPermalinks = isMonitoredReportFilterType
      ? getPermalinks(filteredMonitors)
      : [];

    setQueryParams({
      permalink: monitoredPermalinks,
      report_type: reportTypes,
      page: 1,
    });
  }, [
    setQueryParams,
    isMonitoredReportFilterType,
    filterTypes,
    hasZeroReports,
    queryParams.search_by,
    queryParams.sort_by,
    queryParams.sort_direction,
  ]);

  let availableFilterTypeOptions = { ...reportTypeFilterOptions };
  if (!isMonitoredReportVariation) {
    delete availableFilterTypeOptions.monitored_report;
  }

  const deleteAllMutation = useReportDeleteAll(setCurrentReports);
  const deleteAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    deleteAllMutation.mutate();
    setDeleteAllReportsModalOpen(false);
  };

  const handleGoBack = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDeleteAllReportsModalOpen(false);
  };

  const deleteCurrentReport = (permalink: string) =>
    setCurrentReports(
      currentReports.filter(
        (report: RecentReportSerialized) => report.id !== permalink
      )
    );

  const handlePageChange = (page: number) => {
    setQueryParams({ page });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setCurrentReports(reports);
  }, [reports]);

  useEffect(() => {
    document.title = 'Recent Reports - BeenVerified';
  }, []);

  if (!isLoading && reports.length === 0 && !hasRunReports({ session })) {
    return (
      <Stack spacing={3.5}>
        <Stack direction="row" spacing={1} alignItems="center">
          <HistoryRounded sx={{ fontSize: '1.25rem' }} />
          <Text variant="h3">Recent Reports</Text>
        </Stack>
        <NullState />
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={3.5} padding={2.5} paddingTop={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <HistoryRounded sx={{ fontSize: '1.25rem' }} />
          <Text variant="h3">Recent Reports</Text>
        </Stack>
        <SearchAndFilterControls
          filterTypes={filterTypes}
          setFilterTypes={(filterTypes) => {
            setFilterTypes(filterTypes);
          }}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          reportTypeFilterOptions={
            isInternationalVinTest
              ? {
                  vehicle_report: availableFilterTypeOptions.vehicle_report,
                }
              : availableFilterTypeOptions
          }
          sort_by={sort_by}
          sort_direction={sort_direction}
        />
        <Stack spacing={1.5} direction="row">
          <Text variant="caption">
            {currentReports.length}{' '}
            {pluralizeString('report', currentReports.length)} viewed
          </Text>
          <Divider
            sx={{ borderColor: 'text.primary' }}
            orientation="vertical"
            flexItem
          />
          <DeleteAllButton
            setDeleteAllReportsModalOpen={setDeleteAllReportsModalOpen}
          />
        </Stack>
        {isLoading ? (
          <LoadingReports />
        ) : (
          <ReportList
            reportList={currentReports}
            deleteCurrentReport={deleteCurrentReport}
            RecentReportsCardDescription={RecentReportsCardDescription}
          />
        )}
        {!isLoading && pageCount > 1 && (
          <Stack
            spacing={2}
            direction={'row'}
            sx={{ justifyContent: 'center' }}
          >
            <Pagination
              page={queryParams.page}
              count={pageCount}
              onChange={(_: any, page: number) => handlePageChange(page)}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-page, & .MuiPaginationItem-ellipsis': {
                  display: 'none',
                },
              }}
            />
          </Stack>
        )}
        <>
          <DeleteModal
            isOpen={deleteAllReportsModalOpen}
            setIsOpen={handleGoBack}
            deleteMutation={deleteAll}
            title={'Delete all reports confirmation'}
            confirmationText="Are you sure you want to delete ALL of your reports?"
            deleteText="Delete all"
          />
        </>
      </Stack>
    </>
  );
};
