import { Person } from '@mui/icons-material';
import { Text } from '@ltvco/refresh-lib/theme';
import { openReportInNewTab } from '@ltvco/refresh-lib/v1';
import { BaseCard } from '../BaseCard';
import {
  PersonIconWrapper,
  InfoSection,
  NameTypography,
  SecondaryTextTypography,
  SecondaryTextSubtitleTypography,
  CustomList,
  PhonesList,
} from '../common/components';
import { formatName } from '../common/utils';
import { ResidentCardData } from '../common/types';
import { formatPhone, getDatesSeen, nameize } from '@ltvco/refresh-lib/utils';
import { constants } from 'appConstants';

export function MobileResidentCard({
  id,
  addresses,
  names,
  phones,
}: ResidentCardData) {
  const handleOpenAddressReport = () => {
    openReportInNewTab(
      { bvid: id || '', searchType: 'person' },
      constants.links.baseUrl
    );
  };
  const fullName = nameize(names[0]?.full) || '';
  const formattedName = formatName(fullName || '');
  const dateRange = getDatesSeen(addresses?.[0]) || '-';
  const phone = phones?.[0]?.number || '';
  const formattedPhone = formatPhone(phone) || '';
  const formattedPhones =
    phones?.slice(1).map((phone) => formatPhone(phone.number || '-')) || [];
  const aliases = names.slice(1).map((name) => nameize(name.full)) || [];

  return (
    <BaseCard
      name={fullName}
      isExpandable={true}
      onDetailsClick={handleOpenAddressReport}
      renderIcon={() => (
        <PersonIconWrapper>
          <Person color="primary" fontSize="small" />
        </PersonIconWrapper>
      )}
      renderHeader={() => (
        <>
          <NameTypography variant="subtitle1" fontWeight="bold">
            {formattedName}
          </NameTypography>
          {dateRange && (
            <SecondaryTextTypography variant="body2">
              Date Seen: {dateRange}
            </SecondaryTextTypography>
          )}
          {formattedPhone && (
            <SecondaryTextTypography variant="body2">
              {formattedPhone}
            </SecondaryTextTypography>
          )}
        </>
      )}
      renderExpandedContent={() => (
        <InfoSection>
          <SecondaryTextSubtitleTypography variant="subtitle2">
            Aliases
          </SecondaryTextSubtitleTypography>
          {aliases.length > 0 ? (
            <CustomList items={aliases} />
          ) : (
            <Text variant="body2">-</Text>
          )}

          <SecondaryTextSubtitleTypography variant="subtitle2">
            Phone
          </SecondaryTextSubtitleTypography>
          {phones.length > 0 ? (
            <PhonesList phones={formattedPhones} />
          ) : (
            <Text variant="body2">-</Text>
          )}
        </InfoSection>
      )}
      footerText={formattedName}
    />
  );
}
