import {
  Box,
  Stack,
  Text,
  styled,
  Divider,
  List,
  ListItem,
} from '@ltvco/refresh-lib/theme';
import { titleize } from '@ltvco/refresh-lib/utils';
import { ContactCompany } from '@ltvco/refresh-lib/v1';
import { ChevronRight, Spellcheck } from '@mui/icons-material';
import {
  deduplicateArray,
  deduplicateObjectsByKey,
  MAX_LIST_ITEMS,
} from './utils';
import {
  CompaniesListProps,
  CustomListProps,
  PhonesListProps,
  RelativesListProps,
} from './types';

export const CardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: `${theme.spacing(1.5)}`,
  border: '1px solid #ddd',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1.5),
  position: 'relative',
  color: theme.palette.text.primary,
}));

export const HeaderStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  padding: theme.spacing(1),
  width: '36px',
  height: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PersonIconWrapper = styled(IconWrapper)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

export const InfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1, 1, 1),
  color: theme.palette.text.secondary,
}));

export const ExpandButton = styled(Box)<{ mt?: number }>(
  ({ theme, mt = 0 }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(mt),
    color: theme.palette.text.secondary,

    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  })
);

export const AdditionalDetailsBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(3),
  cursor: 'pointer',
}));

export const StyledChevron = styled(ChevronRight)(({ theme }) => ({
  color: theme.palette.grey[500],
  cursor: 'pointer',
  fontSize: 20,
}));

export const NameTypography = styled(Text)<{ component?: string }>(
  ({ theme }) => ({
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  })
);

export const SecondaryTextTypography = styled(Text)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const SecondaryTextSubtitleTypography = styled(Text)(({ theme }) => ({
  '&.MuiTypography-root': {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
}));

export const SpellCheckIcon = styled(Spellcheck)(({ theme }) => ({
  height: '16px',
  width: '16px',
  margin: 'auto',
  color: theme.palette.neighborhoodWatch?.main,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export const DetailsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'flex-start',
  position: 'relative',
}));

export const ListContentItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  cursor: 'pointer',
}));

export const MoreItemsIndicator = ({
  items = [],
  displayed = MAX_LIST_ITEMS,
}: {
  items: unknown[];
  displayed: number;
}) => {
  const total = items.length;

  if (total === 0 || total <= displayed) return null;

  return (
    <SecondaryTextTypography variant="body2" sx={{ mt: 1 }}>
      + {total - displayed} more
    </SecondaryTextTypography>
  );
};

export const CustomList = ({
  items,
  maxItems = MAX_LIST_ITEMS,
}: CustomListProps) => {
  // Deduplicate items
  const uniqueItems = items ? deduplicateArray(items) : [];

  return (
    <List dense>
      {uniqueItems.slice(0, maxItems).map((item, index) => (
        <ListItem
          key={index}
          sx={{
            textTransform: 'capitalize',
            paddingLeft: '8px',
            alignItems: 'start',
          }}
        >
          <Box component="span" sx={{ marginRight: '7px' }}>
            &#8226;
          </Box>
          {titleize(item).toLowerCase()}
        </ListItem>
      ))}
      {<MoreItemsIndicator items={uniqueItems} displayed={maxItems} />}
    </List>
  );
};

export const CustomListItem = ({
  item,
  hasAction = false,
  onClickHandler,
  index = 0,
  isCapitalized = true,
}: {
  item: string;
  hasAction?: boolean;
  onClickHandler?: (index: number) => void;
  index?: number;
  isCapitalized?: boolean;
}) => (
  <>
    <ListContentItem
      onClick={
        hasAction && onClickHandler ? () => onClickHandler(index) : undefined
      }
    >
      <SecondaryTextTypography
        variant="body2"
        sx={{
          textTransform: isCapitalized ? 'capitalize' : 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {isCapitalized ? titleize(item).toLowerCase() : item}
      </SecondaryTextTypography>
      {hasAction && <StyledChevron />}
    </ListContentItem>
    <Divider />
  </>
);

export const RelativesList = ({
  uniqueRelatives,
  uniqueRelativesBvIds,
  onClickHandler,
  maxItems = MAX_LIST_ITEMS,
}: RelativesListProps) => {
  return (
    <Box>
      {uniqueRelatives
        .slice(0, maxItems)
        .map((relative: string, index: number) => (
          <CustomListItem
            key={index}
            item={relative}
            hasAction={!!uniqueRelativesBvIds?.[index]}
            onClickHandler={onClickHandler}
            index={index}
            isCapitalized={true}
          />
        ))}
      {<MoreItemsIndicator items={uniqueRelatives} displayed={maxItems} />}
    </Box>
  );
};

export const CompaniesList = ({
  companies,
  onClickHandler,
  maxItems = MAX_LIST_ITEMS,
}: CompaniesListProps) => {
  // Convert to array and deduplicate
  const companiesArray = Array.isArray(companies) ? companies : [companies];

  let uniqueCompanies: (string | ContactCompany)[];

  if (typeof companiesArray[0] === 'string') {
    // For string arrays
    uniqueCompanies = deduplicateArray(companiesArray as string[]);
  } else {
    // For object arrays
    uniqueCompanies = deduplicateObjectsByKey(
      companiesArray as ContactCompany[],
      'name'
    );
  }

  return (
    <Box>
      {uniqueCompanies.slice(0, maxItems).map((company, index) => (
        <CustomListItem
          key={index}
          item={typeof company === 'string' ? company : company.name}
          hasAction={true}
          onClickHandler={onClickHandler}
          index={index}
          isCapitalized={false}
        />
      ))}
      {<MoreItemsIndicator items={uniqueCompanies} displayed={maxItems} />}
    </Box>
  );
};

export const PhonesList = ({
  phones,
  maxItems = MAX_LIST_ITEMS,
}: PhonesListProps) => {
  // Deduplicate phone numbers
  const uniquePhones = phones ? deduplicateArray(phones) : [];

  return (
    <List dense>
      {uniquePhones.slice(0, maxItems).map((phone, index) => (
        <CustomListItem
          key={index}
          item={phone}
          index={index}
          isCapitalized={false}
        />
      ))}
      {<MoreItemsIndicator items={uniquePhones} displayed={maxItems} />}
    </List>
  );
};
