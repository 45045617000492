import React from 'react';
import { PersonSearchNoResults, type Record } from '@ltvco/refresh-lib/v1';
import { ConsolidatedSearchResultCard } from '@ltvco/refresh-lib/v2';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { MobilePersonCard as MobilePersonCardVariation1 } from './NewCardsDesignVariation1/PersonCards/MobilePersonCard';
interface PersonCardProps {
  searchPersonResult: Record[];
}

export const PersonCards = ({ searchPersonResult }: PersonCardProps) => {
  const { isMobile } = useScreenSize();
  const isInNewCardDesignVariation1 = useFeatureIsOn('prop-2906');
  const isInMobileAndNewCardDesignVariation1 =
    isMobile && isInNewCardDesignVariation1;

  if (!searchPersonResult || searchPersonResult.length === 0) {
    return <PersonSearchNoResults />;
  }

  return (
    <>
      {searchPersonResult.map((person: Record, index: number) => {
        const allBvIds = Array.isArray(person.relativesNotSerialized)
          ? person.relativesNotSerialized
              .map((relative) => relative.bvid)
              .join(', ')
          : '';

        if (isInMobileAndNewCardDesignVariation1) {
          return (
            <React.Fragment key={index}>
              <MobilePersonCardVariation1 {...person} />
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={index}>
            <ConsolidatedSearchResultCard
              {...person}
              isConsolidatedSearch={true}
              relativeBvId={allBvIds}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
